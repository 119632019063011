import React from "react"

import Container from "components/container"
import Hero from "components/hero"
import Layout from "components/layout"

export default () => {
  const browser = typeof window !== "undefined" && window

  // If we're not in the browser yet, don't render this page
  // To avoid this issue https://github.com/gatsbyjs/gatsby/issues/18425
  return (
    <>
      {browser && (
        <Layout
          title="404"
          description="We couldn't find the page you were looking for"
        >
          <Container>
            <Hero
              title="Oh dear, that's an error"
              subtitle="We couldn't find the page you were looking for."
            />
          </Container>
        </Layout>
      )}
    </>
  )
}
